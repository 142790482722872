import React, { useState, useCallback } from "react";
import { useStyles } from "./styles";
import Header from "./parts/Header";
import MessageDialog from "./parts/MessageDialog";
import FeedbackForm from "./parts/FeedbackForm";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { withForm } from "@manakin/core";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import { showSnackbarMessage } from "@manakin/core/actions";
import { useDispatch } from "react-redux";
import {
	GQL_GIVE_FEEDBACK_ELEMENT,
	GQL_FETCH_FEEDBACK_BY_TOKEN,
} from "./graphql";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { useTranslation } from 'react-i18next';

const formBundle = {
	feedback: {
		required: true,
	},
	name: {
		required: true,
	},
};

const FeedbackPage = (props) => {
	const { form } = props;
	const classes = useStyles();
	const [mutate] = useMutation(GQL_GIVE_FEEDBACK_ELEMENT);
	const [dialogOpen, setDialogOpen] = useState(false);
	const params = useParams();
	const { t } = useTranslation();

	// Query
	const { data: feedbackByTokenData = {} } = useQuery(
		GQL_FETCH_FEEDBACK_BY_TOKEN,
		{
			variables: {
				token: params.token,
			},
		}
	);
	const { feedbackByToken = {} } = feedbackByTokenData;

	// Dispatch functions
	const dispatch = useDispatch();
	const onError = useCallback(
		() =>
			dispatch(
				showSnackbarMessage({
					text: t("common.save-failed"),
					variant: "error",
				})
			),
		[dispatch]
	);

	const handleSubmit = () => {
		form.onSubmit()
			.then((r) => {
				if (r) {
					mutate({
						variables: {
							input: {
								feedback: r.feedback,
								respondent: r.name,
								token: params.token,
							},
						},
					}).then((r) => {
						if (!r.errors) {
							setDialogOpen(true);
						} else {
							onError();
						}
					});
				}
			})
			.catch((e) => {
				onError();
			});
	};

	const handleClose = () => {
		setDialogOpen(!dialogOpen);
	};

	return (
		<div className={classes.root}>
			<Typography variant="h6" className={classes.topTitle}>
				{t("app.workforms.ask-feedback.feedback-page.top-title")}
			</Typography>
			<div className={classes.inner}>
				<Header data={feedbackByToken} />
				<FeedbackForm form={form} />
				<Button
					variant="contained"
					color="primary"
					fullWidth
					className={classes.button}
					onClick={handleSubmit}
				>
					{t("common.save")}
				</Button>
			</div>
			<MessageDialog open={dialogOpen} onClose={handleClose} />
		</div>
	);
};

export default compose(withForm(formBundle))(FeedbackPage);
