import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                backgroundColor: theme.manakin.primaryColor[500],
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
            },
            topTitle: {
                position: 'absolute',
                left: '10px',
                top: '10px',
                textTransform: 'uppercase',

                [theme.breakpoints.up('md')]: {
                    left: '40px',
                    top: '50px',
                },
            },
            inner: {
                maxWidth: '550px',
                width: '100%',
                padding: '40px 20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                [theme.breakpoints.up('md')]: {
                    padding: '12rem 2rem',
                },
            },
            header: {
                marginBottom: '4rem',
            },
            content: {
                textAlign: 'center',
                fontWeight: '400',
            },
            title: {
                maxWidth: '48rem',
                marginBottom: '1rem',
                [theme.breakpoints.up('md')]: {
                    marginBottom: '4rem',
                },
            },
            input: {
                width: '100%',
                marginBottom: '2rem',
            },
        }),
    {
        name: 'AppFeedbackPage',
    }
);
