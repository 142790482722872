import gql from "graphql-tag";
import { backgroundImageAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_GIVE_FEEDBACK_ELEMENT = gql`
	mutation giveFeedback($input: GiveFeedbackInput) {
		giveFeedback(input: $input) {
			feedback {
				feedback
				respondent
				token
			}
		}
	}
`;

export const GQL_FETCH_CMS_WORK_FORM_ASK_FEEDBACK = gql`
	query askFeedback($id: ID!) {
		askFeedback(id: $id) {
			id
			title
			type
			backgroundImage {
				${backgroundImageAsset}
			}
			introduction
			externTitle
			externIntroduction
			author {
				initials
				lastName
				roles {
					id
					name
				}
			}
			createdOn
			lastModifiedBy {
				initials
				lastName
			}
			lastModifiedOn
			tags {
				id
				name
			}
		}
	}
`;

export const GQL_FETCH_FEEDBACK_BY_TOKEN = gql`
	query feedbackByToken($token: String!) {
	    feedbackByToken(token: $token) {
	        askFeedback {
	        	id
	            hintText
	            externIntroduction
	        }
	        user {
	            fullName
	        }
	    }
	}
`;
