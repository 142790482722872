import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    dialog: {
        textAlign: 'center',
    },
    title: {
        marginBottom: '15px'
    },
    closeButton: {
        position: 'absolute',
        right: '15px',
        top: '20px',
    },
    thumbsWrap: {
        margin: '0 auto 20px',
        width: '100px',
        height: '100px',
        background: theme.palette.success.light
    },
    thumbsIcon: {
        width: '35px',
        height: '35px',
        color: theme.palette.success.main
    },
    contentWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        paddingTop: '40px',
        flexDirection: 'column'
    }
}));

const MessageDialog = (props) => {
    const { onClose, open } = props
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MuiDialog open={open} fullWidth className={classes.dialog} maxWidth="sm">
            <div className={classes.contentWrap}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
                <Avatar className={classes.thumbsWrap}>
                    <ThumbUpAltOutlinedIcon className={classes.thumbsIcon} />
                </Avatar>
                <Typography variant="h4" className={classes.title}>
                    {t("common.thanks")}
                </Typography>
                <Typography>
                    {t("app.workforms.ask-feedback.feedback-page.thanks-text")}
                </Typography>
            </div>
        </MuiDialog>
    );
};

export default MessageDialog;
