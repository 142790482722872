import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const {data} = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.header}>
            <Typography
                variant="h3"
                className={classNames(classes.content, classes.title)}
            >
                {t("app.workforms.ask-feedback.feedback-page.title", { name: (data && data.user && data.user.fullName) || t("common.student") })}
            </Typography>
            <Typography className={classes.content}>
                {(data && data.askFeedback && data.askFeedback.externIntroduction) || ""}
            </Typography>
        </div>
    );
};

export default Header;
