import React from 'react';
import { TextField, SpeechField } from '@manakin/core';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';

const FeedbackForm = (props) => {
    const { form } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className={classes.input}>
                <SpeechField
                    name="feedback"
                    label={t("app.workforms.ask-feedback.feedback-page.fields.feedback.label")}
                    form={form}
                    InputProps={{
                        placeholder: t("app.workforms.ask-feedback.feedback-page.fields.feedback.label"),
                    }}
                />
            </div>
            <div className={classes.input}>
                <TextField
                    name="name"
                    label={t("app.workforms.ask-feedback.feedback-page.fields.name.label")}
                    className={classes.input}
                    form={form}
                    InputProps={{
                        placeholder: t("app.workforms.ask-feedback.feedback-page.fields.name.label"),
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default FeedbackForm;
