import React from "react";
import CoreAppMenu from "@manakin/app-core/AppMenu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { AccessControl } from "@manakin/app-core";
import { managementRole, trainerRoles } from "@manakin/app-core/lib";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {},
	link: {
		color: theme.manakin.mainMenuColor[500],
		textDecoration: "none",
		fontSize: "1.8rem",
		[theme.breakpoints.up("md")]: {
			fontSize: "3rem",
		},
	},
	activeLink: {
		color: theme.manakin.mainMenuColor[500],
		borderBottom: ".2rem solid",
	},
	listItemRoot: {
		paddingTop: theme.manakin.mainMenuListItemPaddingTop,
		paddingBottom: theme.manakin.mainMenuListItemPaddingBottom,
		paddingLeft: "0",
		paddingRight: "0",
	},
	listItemTextRoot: {
		padding: "0",
	},
});

const AppMenu = props => {
	const { classes } = props;
	const { t } = useTranslation();

	return (
		<CoreAppMenu
			renderGeneral={() => (
				<List>
					<AccessControl not={[ ...trainerRoles, managementRole ]}>
						<ListItem classes={{ root: classes.listItemRoot }}>
							<NavLink
								className={classes.link}
								activeClassName={classes.activeLink}
								to={"/"}
								exact
							>
								<ListItemText
									classes={{
										primary: classes.link,
										root: classes.listItemTextRoot,
									}}
									primary={t("app.menu.programs")}
								/>
							</NavLink>
						</ListItem>
						<ListItem classes={{ root: classes.listItemRoot }}>
							<NavLink
								className={classes.link}
								activeClassName={classes.activeLink}
								to={"/factcards"}
								exact
							>
								<ListItemText
									classes={{
										primary: classes.link,
										root: classes.listItemTextRoot,
									}}
									primary={t("app.menu.fact-cards")}
								/>
							</NavLink>
						</ListItem>
					</AccessControl>
					<ListItem classes={{ root: classes.listItemRoot }}>
						<NavLink
							className={classes.link}
							activeClassName={classes.activeLink}
							to={"/faq"}
							exact
						>
							<ListItemText
								classes={{
									primary: classes.link,
									root: classes.listItemTextRoot,
								}}
								primary={t("app.menu.faq")}
							/>
						</NavLink>
					</ListItem>
				</List>
			)}
		/>
	);
};

export default withStyles(styles, { name: "AppMenu" })(AppMenu);
