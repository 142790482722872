import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { white, mountain, black } from '../colors';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.jpg';
import accountBackgroundImage from '../../assets/account-background.jpg';
import boxHeaderImage from '../../assets/boxheader.png';
import logo from '../../assets/logo.png';
import footerLogo from '../../assets/footerLogo.png';
import pdfIcon from '../../assets/pdf.svg';
import loginVisual from '../../assets/visual.svg';
import dashboardBg from '../../assets/dashboard-bg.jpg';
import calendarVisual from '../../assets/calendar-visual.svg';
import reportVisual from '../../assets/report-visual.svg';
import closeIcon from '../../assets/close-icon.svg';
import refreshIcon from '../../assets/refresh-icon.svg';
import nextIcon from '../../assets/next-icon.svg';
const yellow = createColor('yellow');
const green = createColor('#1CD172');
const errorColor = createColor('#E8302F');
const borderRadius = '4px';

const theme = (obj = {}) => {
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};

	_obj._primary = '#E1E2E5';
	_obj._secondary = '#0d1011';
	_obj._tertiary = '#1A44E5';
	_obj._palette = obj.themeColor || '#1A44E5';
	_obj.indication = obj.indicationColor || '#1A44E5';
	_obj._secondaryWorkform = '#0d1011';
	_obj._buttonBorderColor = obj.buttonBorderColor || _obj.palette;
	_obj.buttonRadius = obj.buttonBorderRadius || '8px';
	_obj.defaultRadius = '4px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _obj._secondary;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._secondary;
	_obj.primaryElement = obj.primaryWorkformColor || _obj._palette;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._secondaryWorkform;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._palette;
	_obj.primaryWorkform = obj.primaryWorkformColor || _obj._palette;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || _obj._secondaryWorkform;
	_obj.buttonBorderColor = createColor(_obj._buttonBorderColor);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);
	_obj.defaultBoxColor = createColor(_obj.defaultBox);

	_obj.successColor = createColor('#1CD172');
	_obj.wrongColor = createColor('#E82F2F');
	_obj.warningColor = createColor('#FE8612');
	_obj.secondaryButton = createColor('#0D1011');

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.defaultBoxColor;
	_obj.secondaryPaletteColor = createColor(_obj._palette);

	//type colors
	_obj.typeColor = _obj._palette;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.defaultBoxColor;

	//box backgroundColors
	_obj.defaultBoxBGColor = createColor('#0D1011');

	//box name colors
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.defaultBoxColor;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = createColor('#E1E2E5');
	_obj.defaultTitleColor = _obj.defaultBoxColor;
	_obj.defaultContentColor = createColor('#151b1e');
	_obj.homeLinkColor = _obj.defaultContentColor;
	_obj.secondaryContentColor = createColor('#C4C4C4');
	_obj.secondaryBackgroundColor = createColor('#0D1011');

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = createColor(_obj._palette);
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.indicationColor;
	_obj.defaultContentHover = _obj.createdPalette;
	_obj.LessonButtonColor = _obj.defaultBoxColor;
	_obj.defaultBorderHoverColor = _obj.createdPalette;

	//rest
	_obj.largeWrapperLength = '1540px';
	_obj.defaultBorderColor = createColor('#C4C4C4');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.correctAnswer = _obj.createdPalette;
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '850px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = 'Bio Sans';
	_obj.secondaryTitleFont = 'Bio Sans';
	_obj.workformQuestionFont = 'Bio Sans';
	_obj.defaultAnswerFont = 'Bio Sans';
	_obj.defaultContentFont = 'Bio Sans';
	_obj.restFont = 'Anton';

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultTitleFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 500,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.listStyle = {
		'& ol, & ul': {
			padding: 0,
			counterReset: 'li-index',
			listStyle: 'none',
		},
		'& li': {
			position: 'relative',
			paddingLeft: '2.75rem',
		},
		'& li::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: 0,
		},
		'& ul li::before': {
			top: '12px',
			width: '8px',
			height: '8px',
			background: 'currentColor',
			borderRadius: '50%',
		},
		'& ol li::before': {
			counterIncrement: 'li-index',
			content: 'counter(li-index)',
		},
	};

	_obj.breakpoints = createBreakpoints({});

	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[50],
				main: green[500],
				dark: green[800],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[50],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: errorColor[400],
				main: errorColor[600],
				dark: errorColor[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			calendarVisual: calendarVisual,
			closeIcon: closeIcon,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultRadius: props.defaultRadius,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			homeLinkColor: props.homeLinkColor,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			nextIcon: nextIcon,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			refreshIcon: refreshIcon,
			reportVisual: reportVisual,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryContentColor: props.secondaryContentColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			successColor: props.successColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			visual: {
				backgroundImage: `url(${loginVisual})`,
				width: '100%',
				height: '100%',
				backgroundSize: 'cover',
			},
			warningColor: props.warningColor,
			workformTitleFont: props.workformTitleFont,
			wrongColor: props.wrongColor,
		}),
		overrides: {
			AppAccount: {
				deleteButton: {
					color: 'red',
				},
				confirmDeleteButton: {
					backgroundColor: 'red',
				},
			},
			AppAccountMenuItem: {
				listItem: {
					whiteSpace: 'nowrap',
					justifyContent: 'flex-start',
				},
				listItemTitle: {
					fontSize: '1.8rem',
					fontWeight: '800',
				},
			},
			AppAccountWrapper: {
				underline: {
					display: 'none',
				},
				root: {
					background: 'none',
				},
				title: {
					fontFamily: props.restFont,
					fontSize: '8rem',
					lineHeight: '8rem',
					letterSpacing: '0rem',
					textTransform: 'uppercase',
					fontWeight: '400',
				},
			},
			AppAgenda: {
				root: {
					backgroundImage: `url(${dashboardBg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'top',
				},
			},
			AppAssignmentDownloadButton: {
				title: {
					color: white[500],
				},
				button: {
					backgroundColor: props.secondaryBackgroundColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: props.secondaryBackgroundColor[500],
						color: props.createdPalette[500],
						'& $title': {
							color: props.createdPalette[500],
						},
					},
				},
			},
			AppAssignmentHeader: {
				root: {
					backgroundColor: props.createdPalette[500],
				},
				content: {
					backgroundColor: props.createdPalette[500],
				},
			},
			AppBar: {
				menuText: {
					color: black[500],
				},
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.3rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				programsDropdownRoot: {
					[props.breakpoints.down('sm')]: {
						left: '25px',
						right: '25px',
						width: 'auto',
						marginTop: '120px',
						transform: 'none',
					},
				},
				menuButtonLabel: {
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
					fontWeight: 'bold',
					marginLeft: '14px',
					textTransform: 'uppercase',
					letterSpacing: '2px',
				},
				iconColor: {
					color: props.mountainColor[500],
				},
				buttonRoot: {
					marginLeft: '1rem',
					color: props.mountainColor[500],
				},
				buttonLabel: {
					color: props.mountainColor[500],
				},
				backButtonRoot: {
					borderColor: props.createdPalette[500],
				},
				secondaryColors: {
					'& $appBarBackground': {
						backgroundColor: props.secondaryBackgroundColor[500],
					},
					'& $menuButtonLabel': {
						color: white[500],
					},
					'& $menuText': {
						color: white[500],
					},
					'& $iconColor': {
						color: white[500],
					},
					'& $backButtonRoot': {
						color: white[500],
					},
					'& $buttonLabel': {
						color: white[500],
					},
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				body: {
					color: white[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBookCheckHeader: {
				headingRoot: {
					letterSpacing: 0,
				},
			},
			AppBottomButtons: {
				question: {
					textTransform: 'uppercase',
				},
				iconContainer: {
					display: 'block',
				},
				closeButtonRedo: {
					fontFamily: props.restFont,
					backgroundColor: props.defaultBoxColor[500],
				},
				closeButtonRedoLabel: {
					[props.breakpoints.up('md')]: {
						fontSize: '8rem',
						lineHeight: '8rem',
						fontWeight: '400',
						minHeight: '45rem',
					},
				},
				closeButtonSecondary: {
					backgroundColor: props.indicationColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: props.indicationColor[700],
						color: white[500],
					},
				},
			},
			AppBox: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					paddingBottom: '100px',
				},
			},
			AppBoxCard: {
				boxRoot: {
					borderRadius: props.defaultRadius,
				},
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				inner: {
					padding: '2.25rem',
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
					},
					'&:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
						'& $overflow': {
							transform: 'scale(1) translate(2%, -20px)',
						},
						'& $boxDescription': {
							visibility: 'visible',
							transform: 'none',
							opacity: 1,
						},
						'& $boxSubtitle': {
							display: 'none',
						},
						'& $boxName': {
							opacity: 0,
							visibility: 'hidden',
							transform: 'translateY(-32px)',
						},
						'& $boxCardLabel': {
							transform: 'none',
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},
				boxName: {
					fontWeight: '700',
					fontFamily: props.defaultTitleFont,
					transition: 'all .2s ease',
					fontSize: '2rem',
					paddingLeft: 0,
					[props.breakpoints.up('md')]: {
						fontSize: '4.4rem',
						lineHeight: '5.5rem',
						maxWidth: '50%',
					},
				},
				boxCardLabel: {
					top: '10px',
					zIndex: 99,
					left: 0,
					position: 'absolute',
					padding: '5px 32px',
					[props.breakpoints.up('md')]: {
						top: '30px',
						padding: '10px 32px',
					},
				},
				boxCardIconLabel: {
					backgroundColor: white[500],
				},
				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
				},
				boxCardText: {
					color: black[500],
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				withOverflow: {},
				listView: {
					borderRadius: 0,
					'&$withOverflow': {
						'&::before': {
							content: "''",
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'white',
						},
						'& $backgroundImage': {
							backgroundColor: 'inherit',
						},
					},
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				boxRootOverflow: {},
				listViewRoot: {
					'&$boxRootOverflow': {
						marginTop: 0,
					},
					'& $withOverflow': {
						overflow: 'hidden',
					},
					'& $overflow': {
						height: '100%',
						transform: 'scale(1.1)',
					},
					'& $withOverflow:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
					},
				},
				percentage: {
					fontSize: '1.4rem',
					letterSpacing: 0,
					width: '4rem',
					height: '4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
						width: '4rem',
						height: '4rem',
					},
				},
				percentageDone: {
					backgroundColor: black[500],
					color: white[500],
				},
			},
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
				smallWrapper: {
					backgroundColor: 'transparent',
					[props.breakpoints.down('md')]: {
						backgroundColor: 'white',
						paddingTop: '20px',
					},
				},
			},
			AppBoxHeader: {
				root: {
					backgroundImage: `url(${boxHeaderImage})`,
					backgroundColor: props.defaultBoxBGColor[500],
					[props.breakpoints.up('md')]: {
						paddingBottom: '30rem',
					},
				},
				wrapper: {
					maxWidth: '800px',
				},
				heading: {
					color: white[500],
					fontFamily: 'Anton',
					textTransform: 'uppercase',
					marginBottom: '0!important',
					[props.breakpoints.up('md')]: {
						lineHeight: '12rem',
						fontSize: '9rem',
					},
					'&:after': {
						display: 'none',
					},
				},
				body: {
					color: props.secondaryContentColor[500],
					[props.breakpoints.up('md')]: {
						marginBottom: '11.4rem',
					},
				},
				resultsPercentage: {
					'&::after': {
						background: `linear-gradient(90deg, rgba(252,122,76,1) 0%, rgba(252,122,76,1) 25%, rgba(252,122,76,0) 25%, rgba(252,122,76,0) 75%, rgba(252,122,76,1) 75%, rgba(252,122,76,1) 100%)`,
						letterSpacing: 0,
						width: '150px',
						color: props.primaryPaletteColor[500],
					},
				},
			},
			AppBoxLayout: {
				root: {
					position: 'relative',
					paddingBottom: '10rem',
					[props.breakpoints.up('md')]: {
						marginTop: '-28.5rem',
						paddingBottom: '14rem',
					},
				},
				wrapper: {
					[props.breakpoints.up('md')]: {
						paddingBottom: '4rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					backgroundImage: 'none',
					right: '12rem',
				},
				listView: {
					backgroundColor: 'transparent',
					paddingBottom: '0',
				},
			},
			AppCaseContent: {
				closeButtonRedo: {
					backgroundColor: props.secondaryBackgroundColor[500],
					color: white[500],
					margin: 0,
					'&:hover': {
						backgroundColor: props.secondaryColor[700],
						color: white[500],
					},
					[props.breakpoints.up('md')]: {
						height: '45rem',
					},
				},
				closeButtonRedoLabel: {
					fontFamily: props.restFont,
					[props.breakpoints.up('md')]: {
						fontSize: '8rem',
					},
				},
				closeButtonClose: {
					backgroundColor: props.primaryWorkformColor[500],
					color: white[500],
					margin: 0,
				},
				question: {
					[props.breakpoints.up('md')]: {
						maxWidth: '1280px',
					},
				},
			},
			AppChoiceQuestion: {
				header: {
					fontFamily: props.workformQuestionFont,
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
			},
			AppClassList: {
				topBar: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
					fontFamily: props.defaultTitleFont,
					fontWeight: 'bold',
					fontSize: '3.6rem',
				},
				underline: {
					display: 'none',
				},
			},
			AppDashboardHeader: {
				heading: {
					fontFamily: 'Anton',
					[props.breakpoints.up('md')]: {
						lineHeight: '8rem',
						fontSize: '8rem',
					},
				},
				title: {
					textTransform: 'uppercase',
				},
				headingName: {
					color: props.createdPalette[500],
				},
				root: {
					backgroundImage: `url(${dashboardBg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'bottom',
					[props.breakpoints.up('md')]: {
						padding: '18rem 0 30rem',
					},
				},
			},
			AppDemoButton: {
				button: {
					borderColor: props.createdPalette[500],
				},
			},
			AppDownloadButton: {
				wrapper: {
					maxWidth: '1020px',
				},
			},
			AppElementCard: {
				videoBG: {
					maxHeight: '410px',
					marginBottom: '-10px',
				},
				playIconContainer: {
					border: `1px solid ${props.defaultBorderColor[500]}`,
					backgroundColor: 'rgba(0,0,0,0.25)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					[props.breakpoints.up('md')]: {
						width: '12.6rem',
						height: '12.6rem',
					},
				},
				playIcon: {
					width: '2rem',
					height: '2.6rem',
				},
				contentColor: {},
				backgroundImage: {
					transition: 'width .3s, height .3s, background-color .3s',
				},
				root: {
					borderRadius: props.defaultRadius,
					'&:hover': {
						'& $backgroundImage': {
							[props.breakpoints.up('md')]: {
								transform: 'scale(1)',
								height: 'calc(100% + 30px)',
							},
						},
					},
				},
				name: {
					letterSpacing: 0,
					fontWeight: 'bold',
					fontFamily: props.defaultTitleFont,
					[props.breakpoints.up('md')]: {
						fontSize: '3.6rem',
						letterSpacing: 0,
						lineHeight: '3.6rem',
					},
				},
				type: {
					fontFamily: props.defaultAnswerFont,
					marginBottom: '1rem',
					fontSize: '1.6rem',
					lineHeight: '2rem',
					color: props.createdPalette[500],
					fontWeight: 'bold',
					textTransform: 'uppercase',
					[props.breakpoints.up('md')]: {
						marginBottom: '2.9rem',
					},
				},
				body: {},
				bodytitle: {},
				bookCheck: {
					[props.breakpoints.up('md')]: {
						minHeight: '20rem',
					},
				},
				bookCheckContent: {
					'& $bodytitle': {
						color: 'white',
					},
					'& $body': {
						lineHeight: '1.25',
						color: 'white',
						marginBottom: '1rem',
					},
				},
				button: {
					backgroundColor: 'transparent',
					color: 'black',
					border: `3px solid white`,
					minHeight: '3rem',
					padding: '1.8rem!important',
					'& span': {
						fontSize: '1.8rem',
						color: 'white',
					},
				},
				done: {
					backgroundColor: `rgba(13, 16, 17, 0.75)`,
					[props.breakpoints.up('md')]: {
						width: '6.4rem',
						height: '6.4rem',
					},
				},
				inCorrect: {
					backgroundColor: `rgba(13, 16, 17, 0.75)`,
				},
				continue: {
					backgroundColor: `rgba(13, 16, 17, 0.75)`,
					'& $doneIcon': {
						color: white[500],
					},
				},
				doneIcon: {
					color: white[500],
				},
				primaryPrefixColor: {
					opacity: '0.5',
				},
				primaryTitleColor: {
					color: white[500],
				},
				bridgeContent: {
					[props.breakpoints.up('md')]: {
						padding: '80px 60px',
					},
					'& $contentColor': {
						'&>ul': {
							paddingLeft: '2rem',
							marginTop: '2rem',
							[props.breakpoints.up('md')]: {
								paddingLeft: '2rem',
							},
						},
					},
				},
				fullWidth: {
					[props.breakpoints.up('md')]: {
						'&$bookCheck': {
							'& $backgroundImage': {
								width: '20rem',
							},
							padding: '2.5rem 5rem 2.5rem 24rem',
						},
					},
				},
				minHeight: {
					[props.breakpoints.up('md')]: {
						minHeight: '400px',
					},
					'& $done': {
						[props.breakpoints.up('md')]: {
							top: '2rem',
							right: '2rem',
						},
					},
				},
			},
			AppElementOverlay: {
				title: {
					fontFamily: props.defaultTitleFont,
					fontWeight: 'bold',
				},
				closeButton: {
					backgroundColor: props.createdPalette[500],
					color: 'white',
					'&:hover': {
						backgroundColor: props.createdPalette[500],
						color: 'white',
					},
				},
				content: {
					backgroundColor: props.defaultBackgroundColor[500],
				},
				primary: {
					backgroundColor: 'transparent',
					'& $left': {
						backgroundColor: 'transparent',
					},
					'& $right': {
						backgroundColor: 'transparent',
					},
					'& $top': {
						backgroundColor: 'transparent',
					},
					'& $title': {
						color: black[500],
					},
				},
				secondary: {
					backgroundColor: white[500],
					'& $left': {
						backgroundColor: white[500],
					},
					'& $right': {
						backgroundColor: white[500],
					},
					'& $top': {
						backgroundColor: white[500],
					},
					'& $title': {
						color: black[500],
					},
				},
			},
			AppElementsRoot: {
				grid: {
					marginTop: '-5rem',
					maxWidth: '1230px',
					marginBottom: 0,
					[props.breakpoints.up('md')]: {
						marginTop: '-28rem',
					},
				},
			},
			AppEvaluation: {
				largeWrapper: {
					maxWidth: props.largeWrapperLength,
					[props.breakpoints.up('md')]: {
						maxWidth: props.largeWrapperLength,
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '10rem 0',
					},
				},
			},
			AppFactcardReferral: {
				root: {
					borderRadius: props.defaultRadius,
					overflow: 'hidden',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				title: {
					fontSize: '2.4rem',
					marginBottom: '1rem',
				},
				body: {
					lineHeight: 1.25,
				},
				multi: {
					'& $title': {
						marginBottom: '.4rem',
					},
					'& $body': {
						marginBottom: '3rem',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFactcardsCard: {
				root: {
					borderRadius: props.defaultRadius,
				},
				name: {
					letterSpacing: 0,
					fontWeight: 500,
					fontFamily: props.groldBold,
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						letterSpacing: 0,
						lineHeight: '5rem',
					},
				},
				backgroundImage: {},
				bodytitle: {
					marginBottom: '1rem',
					color: white[500],
				},
				body: {
					color: white[500],
				},
				bookCheck: {
					backgroundColor: props.createdPalette[500],
					'& $backgroundImage': {
						[props.breakpoints.up('md')]: {
							width: '20rem',
						},
					},
					[props.breakpoints.up('md')]: {
						height: '20rem',
						padding: '2.5rem 5rem 2.5rem 24rem',
					},
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
					},
				},
				button: {
					backgroundColor: 'transparent',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
						color: white[500],
					},
					'&:hover': {
						borderColor: 'white',
						'& span': {
							color: 'white',
						},
					},
				},
				factCardsButton: {
					borderColor: white[500],
				},
			},
			AppFactCardTitles: {
				content: {
					...props.listStyle,
				},
				titlesRoot: {
					overflowY: 'auto',
					[props.breakpoints.up('sm')]: {
						maxHeight: 'calc(100vh - 64px)',
					},
					[props.breakpoints.up('md')]: {
						maxHeight: 'calc(100vh - 100px)',
					},
				},
			},
			AppFeedbackBox: {
				button: {
					marginTop: '1rem',
				},
				done: {
					padding: '2rem 2.6rem',
					'& $feedbackBoxTitle': {
						fontSize: '1.8rem',
						margin: 0,
					},
					'& $contentColor': {
						fontSize: '1.8rem',
						margin: 0,
						marginRight: '5px',
						lineHeight: '3.8rem',
					},
				},
				wrong: {
					borderColor: props.wrongColor[500],
					backgroundColor: props.wrongColor[50],
					'& $contentColor': {
						color: props.wrongColor[500],
					},
				},
				correct: {
					borderColor: props.successColor[500],
					backgroundColor: props.successColor[50],
					'& $contentColor': {
						color: props.successColor[500],
					},
				},
			},
			AppFeedbackPage: {
				root: {
					backgroundImage: `url(${dashboardBg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'bottom',
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppHotspotMapLandscape: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
				question: {
					fontFamily: props.workformQuestionFont,
				},
				container: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppImageWithSubtitle: {
				image: {
					maxWidth: '1020px',
					margin: '0 auto',
				},
				content: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppLayoutButtons: {
				label: {
					color: props.tertiaryColor[500],
					textTransform: 'uppercase',
					fontFamily: props.groldBold,
					letterSpacing: '3px',
				},
				tabsRoot: {
					marginBottom: '4rem',
				},
				tabSelected: {
					backgroundColor: black[500],
				},
			},
			AppLesson: {
				largeWrapper: {
					maxWidth: props.largeWrapperLength,
					[props.breakpoints.up('md')]: {
						maxWidth: props.largeWrapperLength,
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '10rem 0',
					},
				},
			},
			AppLessonBottom: {
				iconContainer: {
					display: 'block',
				},
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				question: {
					textTransform: 'uppercase',
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
					fontFamily: 'Anton',
					backgroundColor: props.createdPalette[500],
					[props.breakpoints.up('md')]: {
						height: '45rem',
						fontSize: '8rem',
					},
					'&:hover': {
						backgroundColor: props.secondaryColor[700],
					},
				},
				backToBoxButton: {
					display: 'none',
				},
				redoQuestionButton: {
					display: 'flex',
					color: white[500],
				},
				nextQuestionButton: {
					backgroundColor: props.secondaryBackgroundColor[500],
					color: white[500],
					display: 'flex',
					'&:hover': {
						backgroundColor: props.secondaryColor[700],
					},
				},
			},
			AppLessonHeader: {
				fontStyle: {
					textTransform: 'uppercase',
					fontSize: '1.6rem',
					lineHeight: '2rem',
					fontFamily: props.defaultContentFont,
					fontWeight: 'bold',
					color: props.createdPalette[500],
				},
				heading: {
					letterSpacing: '0',
					fontFamily: 'Anton',
					[props.breakpoints.up('md')]: {
						fontSize: '12rem',
						lineHeight: '12rem',
					},
				},
				icon: {
					color: props.createdPalette[500],
				},
			},
			AppLessonIntroduction: {
				content: {
					letterSpacing: '.1rem',
					fontWeight: 400,
					'&:first-letter': {
						color: props.defaultTitleColor[500],
						fontSize: '2rem',
						lineHeight: '3.6rem',
						fontWeight: '600',
						'&:first-letter': {
							fontSize: '12rem',
							lineHeight: '12rem',
							margin: '0 6rem 0 0',
						},
					},
					[props.breakpoints.up('md')]: {
						fontSize: '2.1rem',
						lineHeight: '4rem',
						minHeight: '154px',
					},
				},
			},
			AppLogin: {
				link: {
					color: white[500] + '!important',
					'&:hover': {
						color: props.createdPalette[500] + '!important',
					},
				},
				button: {
					color: white[500],
					borderColor: props.createdPalette[500],
					'&:hover': {
						borderColor: white[500],
					},
				},
			},
			AppLoginDemoButton: {
				loginDemoButton: {
					borderRadius: props.homepageButtonRadius,
					color: white[500],
					borderColor: props.createdPalette[500],
					'&:hover': {
						borderColor: white[500],
					},
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: '#0C1011',
				},
				visual: {
					maxWidth: '770px',
				},
				visualTitle: {
					display: 'none',
					[props.breakpoints.up('md')]: {
						display: 'none',
					},
				},
				content: {
					justifyContent: 'flex-end',
					backgroundPosition: 'left center',
					backgroundSize: 'cover',
				},
				headerTitle: {
					backgroundImage: `url(${logo})`,
					height: '140px',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					marginBottom: '60px',
				},
				footNote: {
					color: '#494949',
					flexDirection: 'row',
					justifyContent: 'center',
					minWidth: '430px',
				},
				footNoteExtra: {
					width: '10rem',
					height: '3rem',
					backgroundImage: `url(${footerLogo})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					marginLeft: '20px',
				},
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppMenu: {
				link: {
					color: white[500],
				},
				activeLink: {
					color: props.createdPalette[500],
				},
			},
			AppMoodboard: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				card: {
					padding: '2rem',
					[props.breakpoints.up('md')]: {
						padding: '2rem',
					},
				},
			},
			AppProfilePicture: {
				editPicture: {
					color: white[500],
				},
			},
			AppProgressDashboard: {
				root: {
					backgroundImage: `url(${dashboardBg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'top',
				},
			},
			AppProgressionDashboardButton: {
				block: {
					borderRadius: props.defaultRadius,
					[props.breakpoints.up('md')]: {
						height: '40rem',
					},
				},
			},
			AppRadioGroup: {
				formControlLabel: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppRating: {
				largeWrapper: {
					maxWidth: props.largeWrapperLength,
					[props.breakpoints.up('md')]: {
						maxWidth: props.largeWrapperLength,
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '10rem 0',
					},
				},
			},
			AppRatingRequestsOverview: {
				root: {
					backgroundImage: `url(${dashboardBg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'top',
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				body: {
					textAlign: 'center',
					width: '100%',
					maxWidth: '600px',
					margin: '0 auto 1em',
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppResumeButton: {
				longArrow: {
					'& .arrow': {
						backgroundColor: props.createdPalette[500],
					},
				},
				root: {
					backgroundColor: white[500],
					overflow: 'hidden',
					'&:hover': {
						'& $body1': {
							color: props.createdPalette[500],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.createdPalette[500],
							},
						},
					},
				},
			},
			appRoadmap: {
				wrapper: {
					[props.breakpoints.up('lg')]: {
						padding: '0 15rem',
					},
				},
			},
			appRoadmapStep: {
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
			},
			AppSearchInput: {
				root: {
					margin: 0,
				},
			},
			AppSortSlider: {
				questionContent: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatementQuestion: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				root: {
					borderRadius: borderRadius,
					overflow: 'hidden',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				quote: {
					fontFamily: props.defaultTitleFont,

					fontSize: '2.75rem',
					lineHeight: '1.25',
					letterSpacing: '1px',
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					borderRadius: props.defaultRadius,
					[props.breakpoints.up('md')]: {
						minWidth: '16rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid black`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				content: {
					'& $heading': {
						color: props.defaultTitleColor[500],
					},
				},
			},
			AppTestCard: {
				root: {
					borderRadius: props.defaultRadius,
				},
			},
			AppTitleAndText: {
				wrapper: {
					[props.breakpoints.up('lg')]: {
						padding: '0 9rem',
					},
				},
				root: {
					borderRadius: props.defaultRadius,
					maxWidth: '1200px',
					margin: '0 auto',
					[props.breakpoints.up('md')]: {
						padding: '10rem 0',
					},
				},
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				content: {
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
				columns: {
					[props.breakpoints.up('md')]: {
						columnGap: '4rem',
					},
				},
			},
			AppTitleAndTextImage: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				content: {
					fontSize: '1.8rem',
					lineHeight: '2.8rem',
					...props.listStyle,
				},
				secondary: {
					'& $contentColor': {
						color: white[500],
					},
				},
			},
			AppVideoPlayer: {
				activeIcon: {
					color: props.correctAnswer[500],
				},
				playButton: {
					'& $icon': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
			},
			AppWhatIsWhatItem: {
				arrow: {
					[props.breakpoints.up('md')]: {
						'&:hover': {
							color: white[500],
							backgroundColor: props.createdPalette[500] + '!important',
						},
					},
				},
			},
			AppWorkformLayout: {
				header: {
					fontWeight: 'bold',
					fontSize: '3.6rem',
					lineHeight: '5.6rem',
					fontFamily: props.workformQuestionFont,
					color: black[500],
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
					},
				},
				wrapper: {
					[props.breakpoints.up('md')]: {
						maxWidth: '100%',
					},
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: props.subHeaderColor[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.6rem',
					},
					'&::after': {
						display: 'none',
					},
				},
				container: {
					backgroundColor: white[500],
					maxWidth: '100%',
					padding: '25px 0',
					[props.breakpoints.up('md')]: {
						padding: '6rem',
					},
					[props.breakpoints.up('lg')]: {
						padding: '10rem 15rem',
					},
				},
				primaryColors: {
					paddingTop: 0,
					paddingBottom: 0,
					[props.breakpoints.up('md')]: {
						paddingTop: 0,
						paddingBottom: 0,
					},
					'& $container': {
						backgroundColor: props.primaryWorkformColor[500],
					},
					'& button': {
						backgroundColor: props.secondaryButton[500],
					},
				},
				secondaryColors: {
					paddingTop: 0,
					paddingBottom: 0,
					[props.breakpoints.up('md')]: {
						paddingTop: 0,
						paddingBottom: 0,
						padding: 0,
					},
					'& $container': {
						backgroundColor: props.secondaryWorkformColor[500],
					},
				},
			},
			AuthenticationConfirmReset: {
				root: {
					background: white[500],
				},
			},
			AuthenticationLoginForm: {
				input: {
					color: white[500],
				},
				nativeInput: {
					borderBottom: '1px solid #EFE6DE',
					color: 'white',
				},
				label: {
					color: white[500] + '!important',
				},
				submit: {
					backgroundColor: props.createdPalette[500],
					color: white[500],
				},
				adornment: {
					color: white[500],
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			FactCardsHeader: {
				root: {
					backgroundColor: props.createdPalette[500],
				},
				fontStyles: {
					color: white[500],
					'&:after': {
						display: 'none',
					},
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			HelpButton: {
				fab: {
					backgroundColor: props.primaryColor[500],
					color: 'black',
					'&:hover': {
						color: 'white',
					},
				},
				icon: {
					marginTop: 0,
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiBadge: {
				colorSecondary: {
					backgroundColor: 'red',
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: 'white',
					height: 'auto',
					padding: '20px',
					borderRadius: props.defaultRadius,
					minHeight: '8rem',
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					backgroundColor: props.createdPalette[500],
					color: 'white',
					transition: 'background-color .2s, color .2s',
					'&:hover': {
						backgroundColor: '#2d2d2d',
						color: 'white',
					},
				},
				label: {
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.8rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: '2px solid black',
					padding: '18px',
					'&:hover': {
						border: '2px solid black',
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: black[500],
					color: white[500],
					'&:hover': {
						backgroundColor: black[400],
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiListItemText: {
				primary: {
					margin: 0,
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.8rem',
				},
			},
			MuiPickersDay: {
				daySelected: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
				day: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
			},
			MuiPickersSlideTransition: {
				transitionContainer: {
					'& >*': {
						top: '-6px',
					},
				},
			},
			MuiRadio: {
				colorPrimary: {
					color: 'black',
					'&$checked': {
						color: 'black',
					},
				},
			},
			MuiSelect: {
				icon: {
					top: 'calc(50% - 10px)',
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiSwitch: {
				sizeSmall: {
					width: '77px',
					height: '45px',
					borderRadius: '30px',
					'& $switchBase': {
						'&.Mui-checked': {
							transform: 'translateX(30px)',
						},
					},
					'& $thumb': {
						width: '25px',
						height: '25px',
						transform: 'translate(6px, 6px)',
					},
				},
				colorSecondary: {
					'&.Mui-checked': {
						color: 'white',
					},
					'&.Mui-checked + .MuiSwitch-track': {
						backgroundColor: props.createdPalette[500],
						opacity: 1,
					},
				},
				track: {
					borderRadius: '30px',
				},
			},
			MuiTableCell: {
				root: {
					padding: '20px 0px',
					verticalAlign: 'top',
				},
				head: {
					border: 0,
					fontSize: '1.5rem',
					fontFamily: props.defaultTitleFont,
					fontWeight: 600,
					lineHeight: '18px',
					color: '#BFBFBF',
					borderBottom: `1px solid ${props.defaultBorderColor[500]}`,
				},
				body: {
					paddingTop: '1.8rem',
					paddingBottom: '1.8rem',
					borderBottom: `1px solid ${props.defaultBorderColor[500]}`,
					maxWidth: '220px',
					[props.breakpoints.up('md')]: {
						padding: '3rem 1rem',
					},
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.8rem',
				},
				select: {
					fontSize: '1.8rem',
					lineHeight: '1.8rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '1.8rem',
				},
				h1: {
					fontFamily: 'Anton',
					fontSize: '3rem',
					fontWeight: '500',
					lineHeight: '4.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					letterSpacing: 0,
					textTransform: 'uppercase',
					[props.breakpoints.up('md')]: {
						fontSize: '10rem',
						lineHeight: '12rem',
						letterSpacing: '0rem',
					},
				},
				h2: {
					fontFamily: 'Anton',
					fontSize: '2.75rem',
					lineHeight: 1.25,
					fontWeight: 'bold',
					color: props.defaultContentColor[500],
					letterSpacing: '0.3rem',
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '8rem',
					},
				},
				h3: {
					fontFamily: props.defaultContentFont,
					fontSize: '3rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					fontWeight: 800,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '4.8rem',
						lineHeight: '4.8rem',
						letterSpacing: '0',
					},
				},
				h4: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontWeight: 600,
					fontSize: '3.6rem',
					lineHeight: '5rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '3.6rem',
						marginBottom: '3.6rem',
						fontSize: '3.6rem',
					},
				},
				h5: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.6rem',
					fontWeight: 'bold',
					lineHeight: '3.6rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '2.1rem',
						fontSize: '2.1rem',
						marginBottom: '0rem',
					},
				},
				h6: {
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.6rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.8rem',
					},
				},
				subtitle1: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.2rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
						letterSpacing: '.1rem',
					},
				},
				body1: {
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
					lineHeight: '3.8rem',
					marginBottom: '2rem',
					letterSpacing: '0',
					[props.breakpoints.up('md')]: {
						marginBottom: '3rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontSize: '1.9rem',
					lineHeight: '3rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontWeight: 400,
						fontSize: '2.1rem',
						lineHeight: '4rem',
					},
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
			SingleDot: {
				HotspotBlockText: {
					lineHeight: '28px',
				},
			},
		},
	});

export default theme;
