import logo from "../assets/logo.svg";

export default async () =>
	await new Promise(async (resolve) => {
		const config = {
			whiteLabel: 'BeautyLevel',
			defaultLang: 'nl_NL',
			files: [
				{
					id: 'testfile',
					file: logo,
				},
			],
			pages: {
				appLogin: {
					showHeaderTitle: true,
					headerTitleIsHtml: true,
					showFootNote: true,
					showLogo: false,
					visualTitle: true,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: false,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
					showPersonalDataWarning: true
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: true,
					hideOrganisation: true,
					hideFunction: true,
					hideSchoolClass: true,
                    hideDelete: true
				},
				box: {
					scrollTo: false,
				},
				boxCard: {
					showDescription: true,
				},
				dashboard: {
					scrollTo: true,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
				general: {
					showStudentRapports: false,
					rapportType: 'userprogress',
				},
			},
			elementCardConfig: {
				noPercentage: true
			},
			caseConfig: {
				redoButtons: true,
			},
			assignmentConfig: {
				redoButtons: true,
			},
			general: {
				hideTimer: false,
				downloadRapportage: true,
				showTags: true,
				playButtonAsIcon: true,
				secondStatisticsBox: true,
				voiceApiKey: 'AIzaSyDvhd8Nl6EaTg66thoD5QeksAotFY4osVU',
				showScenesButton: true,
				showMenuLabel: false,
				progressionDashboard: true,
				maxPo: 3,
				maxBPVB: 1,
				poName: 'PO',
				bpvbName: 'BPVB',
				strongPassword: true,
				defaultShowStrongPasswordHint: true,
				excelApi: '/api/explain/export/users/excel',
				bpvMessages: true,
				hideReadSpeaker: true,
			},
			elements: {
				exerciseCard: true,
			},
			customColors: {
				accountAppBar: '#f2f2f2',
				accountAppBarColor: 'primary',
			},
			account: {
				showInvites: true,
				showNotifications: true,
				showBPVBRapport: true,
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});
